.hamburger-menu {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  width: 52px;
  background-color: white;
  border-radius: 10px;

  .menu-icon {
    font-size: 30px;
    cursor: pointer;
    padding: 10px;
    color: #007bff;
  }

  .menu-content {
    position: fixed;
    top: 0;
    left: -100%;
    width: 65%;
    height: 100%;
    background: #fff;
    box-shadow: 4px 0 10px rgba(0, 0, 0, 0.2);
    transition: left 0.3s ease;
    padding: 20px;
    display: flex;
    flex-direction: column;

    .user-space {
      font-size: 20px;
      color: #007bff;
      margin-bottom: 20px;
      padding-bottom: 10px;
      border-bottom: 1px solid #eaeaea;
    }

    a, div {
      display: flex;
      align-items: center;
      margin: 10px 0;
      padding: 10px;
      border-radius: 5px;
      text-decoration: none;
      color: #333;
      font-weight: 500;

      &:hover {
        background-color: #f4f4f8;
      }

      svg {
        margin-right: 10px;
        color: #007bff;
      }
    }

    .menu-link.active {
      background-color: #007bff;
      color: white;
      svg {
        color: white;
      }
    }
  }

  .open {
    left: 0;
  }
}
  .menu-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    cursor: pointer;
    z-index: 999;
  }

