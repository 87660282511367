.bottom-bar {
   position:fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 60px;
    background-color: rgb(223, 230, 233);
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    justify-items: center;
    align-items: center;
   
  
    .icon-container {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  
    .icon {
      font-size: 24px;
      color: #000;
    }
  
    .active {
      background-color: rgb(18, 97, 227);
      color: rgb(223, 230, 233);
      border: 1px solid rgb(18, 97, 227);
      border-radius: 0 0 10px 10px;
      padding: 15px;
      .icon{
        color: rgb(223, 230, 233);
      }

    }
  }
  