.ajout-bl {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #f5f5f5;
    border-radius: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    width: 100%;

    .btn-retour-ajout-bl {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        width: 100%;
    }

    h3 {
        color: #007bff;
        margin-bottom: 20px;
    }

    .btn-AjoutBl {
        background-color: #007bff;
        color: white;
        padding: 10px 20px;
        margin-bottom: 15px;
        border: none;
        border-radius: 5px;
        cursor: pointer;
        text-transform: uppercase;
        font-weight: bold;
        transition: background-color 0.3s;

        &:hover {
            background-color: #0056b3;
        }

        &:last-child {
            margin-top: 20px;
        }
    }

    .MuiTextField-root {
        margin-bottom: 15;
        width: 100%;
        /* Assure que le champ de texte occupe toute la largeur disponible */
    }

    .MuiFormControlLabel-root {
        display: flex;
        align-items: center;
        margin-bottom: 15px;
    }

    .MuiCheckbox-root {
        color: #007bff;
        /* Couleur bleue pour la case à cocher */
    }

    label {
        display: block;
        width: 100%;
        text-align: center;
    }

    input[type="file"] {
        margin-bottom: 15px;
    }
}