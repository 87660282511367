.home-page-new {
    .contain-home-page-new {
        .top-home-page-new {
            text-align: center;
            margin-bottom: 20px;
       
            .titlehome {
                display: flex;
                flex-direction: column;
              background-color: #e9eff5;
              padding: 20px;
              box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
              text-align: center;
          
              h1 {
                color: #0056b3;
                margin: 0;
                font-weight: bold;
              }
              h2{
                color: #0056b3;
                margin: 0;
                font-weight: bold;
              }
            }
            h1, h2 {
                color: #007bff;
            }

            h1 {
                font-size: 24px;
            }

            h2 {
                font-size: 20px;
                margin-top: 20px;
            }
        }

        .map-home-page-new {
            .affaire-block {
                background: #f8f9fa;
                border-radius: 8px;
                padding: 15px;
                margin-bottom: 20px;
                box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

                h3 {
                    color: #0056b3;
                    margin-bottom: 10px;
                }

                h4 {
                    margin-top: 15px;
                    color: #333;
                    font-size: 16px;
                }

                .item-chantier-page-new, .item-aprendre-page-new {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    margin-bottom: 5px;
                    cursor: pointer;
                    padding: 10px;
                    border-radius: 5px;
                    transition: background-color 0.3s ease-in-out;
                    background-color: #eceff1;
                    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
                    position: relative; // Assure a stable layout
                
                    p {
                        flex-grow: 1;
                        margin: 0;
                        font-weight: 500;
                        padding-right: 10px; // Keeps text from touching the span
                    }
                
                    span {
                        flex-shrink: 0; // Prevents the span from shrinking and thus not affecting the flex layout
                        position: absolute; // Takes the span out of the normal document flow
                        right: 10px; // Right aligned within the container
                        top: 50%; // Center vertically
                        transform: translateY(-30%); // Perfect vertical centering
                        font-size: 50px; // Larger font size for visibility
                        user-select: none;
                    }
                }
                
                .item-chantier-page-new span {
                    cursor: pointer; // Makes the star clickable
                    color: #ff5722; // Distinctive color to show it's interactive
                }
                
                .item-aprendre-page-new span {
                    color: #bbb; // Greyed out to indicate it's not clickable
                    cursor: default;
                }
            }
        }
    }
}
