.Workflow {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding-top: 20px;
    overflow-x: hidden;
    overflow-y: auto;
    flex-wrap: nowrap;

    .btn-top-workflow {
        width: 100%;
        display: flex;
        justify-content: space-between;
        margin-bottom: 20px;

        button, a {
            padding: 10px 15px;
            border: none;
            border-radius: 5px;
            background-color: #007bff;
            color: white;
            text-decoration: none;
            font-weight: 500;
            cursor: pointer;
            transition: background-color 0.3s;

            &:hover {
                background-color: #0056b3;
            }
        }
    }

    .contain-worflow {
        width: 100%;
        overflow-x: hidden;
        overflow-y: auto;
        display: flex;
        justify-content: center;
      
       
    }
}