.DefineMyTeam {
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden; 
    max-height: 100vh;
    padding: 0 20px;

    .titleDefineMyTeam {
        text-align: center;

        h2 {
            font-size: 22px;
            margin-bottom: 10px;
            color: #007bff;
        }

        p {
            font-size: 16px;
            color: #666;
            margin-bottom: 20px;
        }
    }

    .rechDefineMyTeam {
        width: 100%;
        margin-bottom: 20px;

        input {
            width: 100%;
            padding: 10px;
            border: 1px solid #ccc;
            border-radius: 5px;
        }
    }

    .utilisateursDefineMyTeam, .utilDefineMyTeam {
        width: 100%;
        margin-bottom: 20px;
        height: 250px;
        overflow-y: auto;
        
        .listDefineMyTeam, .listbasDefineMyTeam {
            display: flex;
            justify-content: space-between;
            align-items: center;
            background-color: #f9f9f9;
            padding: 10px;
            margin-bottom: 10px;
            border-radius: 5px;
            cursor: pointer;
            transition: background-color 0.3s;

            &:hover {
                background-color: #f4f4f8;
            }

            button {
                border: none;
                background-color: #ff4d4d;
                color: white;
                padding: 5px 10px;
                border-radius: 5px;
                cursor: pointer;

                &:hover {
                    background-color: #ff6666;
                }
            }
        }
    }

    button {
        background-color: #007bff;
        color: white;
        padding: 10px 20px;
        border: none;
        border-radius: 5px;
        cursor: pointer;
        transition: background-color 0.3s;

        &:hover {
            background-color: #0056b3;
        }
    }
}
