.MessagerieAffaire{
display: flex;
flex-direction: column;

.top-MessagerieAffaire{
        display: flex;
        justify-content: flex-end;
        align-items: center;
        padding: 10px;
        background-color: #f0f0f0;
        border-bottom: 1px solid #e0e0e0;
      }
    
      .btn-retour {
        margin-left: 70px;
        padding: 5px 10px;
        background-color: #007bff;
        color: white;
        border: none;
        border-radius: 5px;
        cursor: pointer;
        font-size: 14px;
    
        &:hover {
          background-color: #0056b3;
        }
      }
}
