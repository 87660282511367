.ItemClick {
    padding: 15px;
    background-color: #f5f5f5;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  
    h4 {
      color: #007bff;
      margin-bottom: 5px;
    }
  
    p {
      margin-bottom: 15px;
      color: #333;
      line-height: 1.5;
    }
  
    div {
      margin-top: 20px;
  
      h4 {
        margin-bottom: 10px;
      }
  
      img {
        max-width: 100%;
        height: auto;
        display: block;
        margin-bottom: 10px;
        border-radius: 5px;
      }
  
      a {
        display: inline-block;
        background-color: #007bff;
        color: #fff;
        padding: 8px 15px;
        border-radius: 5px;
        text-decoration: none;
        font-size: 14px;
  
        &:hover {
          background-color: #0056b3;
        }
  
        &[download] {
          margin-top: 10px;
        }
      }
    }
  }
  