.choix-actions-rapport {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 20px;
  
  
    .affaire-ref-actions-rapport {
      margin-bottom: 30px;
  
      h2 {
        color: #007bff;
        text-align: center;
      }
    }
  
    .btn-group-actions-rapport {
      display: flex;
      flex-direction: column;
      gap: 20px;
      width: 100%;
  
      button {
        background-color: #007bff;
        color: white;
        padding: 10px 20px;
        border: none;
        border-radius: 5px;
        cursor: pointer;
        font-size: 16px;
        transition: background-color 0.3s;
  
        &:hover {
          background-color: #0056b3;
        }
      }
    }
  }
  