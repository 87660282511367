.ChooseTheChange {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px;
    max-height: 100vh;
    overflow: hidden;

    .btnretour {
        padding: 5px 10px;
        background-color: #007bff;
        color: white;
        border: none;
        border-radius: 5px;
        cursor: pointer;
        align-self: flex-start;
        &:hover {
            background-color: #0056b3;
        }
        margin-bottom: 20px;
    }

    .titleChooseTheChange {
        h1 {
            font-size: 22px;
            color: #007bff;
            margin-bottom: 10px;
        }

        p {
            font-size: 16px;
            color: #666;
            margin-bottom: 20px;
        }
    }

    .btnchoosethechange {
        .btngroupe {
            display: flex;
            flex-direction: column;
            gap: 15px;

            .btn1ChooseTheChange, .btn2ChooseTheChange, .btn3ChooseTheChange {
                background-color: #007bff;
                color: white;
                padding: 10px 20px;
                border: none;
                border-radius: 5px;
                cursor: pointer;
                transition: background-color 0.3s;

                &:hover {
                    background-color: #0056b3;
                }
            }
        }
    }
}
