.AffaireResume {
    display: flex;
    flex-direction: column;
    padding: 20px;
    background: #f8f9fa;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  
    .top-affaireDocuments {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        padding: 10px;
        background-color: #f0f0f0;
        border-bottom: 1px solid #e0e0e0;
      }
    
      .btn-retour {
        margin-left: 70px;
        padding: 5px 10px;
        background-color: #007bff;
        color: white;
        border: none;
        border-radius: 5px;
        cursor: pointer;
        font-size: 14px;
    
        &:hover {
          background-color: #0056b3;
        }
      }
      
    .header-AffaireResume,
    .equipe-prevus-rapport-depart-AffaireResume,
    .contact-client-rapport-depart-AffaireResume,
    .date-chantier-prevus-rapport-depart-AffaireResume,
    .a-prendre-rapport-depart-AffaireResume,
    .materiel-prevus-rapport-depart-AffaireResume {
      margin-bottom: 20px;
      padding: 10px;
      background-color: white;
      border-radius: 5px;
      box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  
      h3 {
        color: #007bff;
        margin-bottom: 10px;
      }
  
      p {
        margin-left: 20px;
        font-size: 14px;
        color: #666;
      }
  
      .liste-tache-rapport-depart-AffaireResume {
        background-color: #e9ecef;
        border-radius: 5px;
        padding: 10px;
        margin-bottom: 10px;
  
        .title-liste-tache-rapport-depart-AffaireResume {
          font-weight: bold;
        }
  
        .detail-ta-ra-de-AffaireResume {
          padding-left: 20px;
  
          p {
            margin: 5px 0;
          }
        }
      }
  
      .a-prendre-pour-taches-rapport-depart-AffaireResume {
        padding: 10px;
        background-color: #dee2e6;
        border-radius: 5px;
        margin-top: 10px;
  
        ul {
          list-style: none;
          padding-left: 20px;
  
          li {
            padding: 3px;
          }
        }
      }
    }
  }