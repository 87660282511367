.EditTache {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    width: 100%;
  
    h2 {
      color: #007bff;
      margin-bottom: 20px;
    }
  
    form {
      width: 100%;
  
      .form-edit-tache, .biggroupe-edit-tache {
        margin-bottom: 20px;
      }
  
      label {
        display: block;
        margin-bottom: 5px;
        color: #666;
      }
  
      input[type="text"], textarea, select {
        width: 100%;
        flex-wrap: nowrap;
        height: 30px;
        border: 1px solid #ccc;
        border-radius: 5px;
      }
  
      textarea {
        resize: vertical;
        height: 100px;
      }
  
      .dateandselect-edit-tache {
        display: flex;
        gap: 20px;
  
        @media (max-width: 768px) {
          flex-direction: column;
        }
      }
  
      .form-group-edit-tache {
        flex: 1;
      }
  
      .datePicker-edit-tache {
        width: 100%;
      }
  
      .form-group-edit-tache-duration-field {
        display: flex;
        align-items: center;
        margin-top: 20px;
        gap: 10px;
        width: 100%;
        
        input[type="number"] {

            width: 70px;
          height: 30px;
          flex-wrap: nowrap;
        }
      }
  
      .submit-button-edit-tache {
        background-color: #007bff;
        color: white;
        padding: 10px 20px;
        border: none;
        border-radius: 5px;
        cursor: pointer;
        font-size: 16px;
        transition: background-color 0.3s;
  
        &:hover {
          background-color: #0056b3;
        }
      }
    }
  }
  