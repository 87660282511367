.home-container {
    display: flex;
    flex-direction: column;
    height: calc(100vh - 50px);
    background-color: #f4f4f8;
    overflow-x: hidden;
  
    .titlehome {
        display: flex;
        flex-direction: column;
      background-color: #e9eff5;
      padding: 20px;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
      text-align: center;
  
      h1 {
        color: #0056b3;
        margin: 0;
        font-weight: bold;
      }
      h2{
        color: #0056b3;
        margin: 0;
        font-weight: bold;
      }
    }
  
    .news-container {
      background-color: white;
      padding: 20px;
      margin: 20px;
      border-radius: 10px;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
      height: 250px;
  
      h2 {
        color: #0056b3;
        margin-bottom: 15px;
        font-weight: bold;
      }
  
      .news-content {
        font-size: 14px;
        line-height: 1.6;
        color: #333;
        overflow-y: auto;
      }
    }
  
    .action-container {
      padding: 20px;
      background-color: #e9eff5;
      box-shadow: 0 -4px 6px rgba(0, 0, 0, 0.1);
  
      button {
        background-color: #007bff;
        color: white;
        padding: 15px 20px;
        border-radius: 25px;
        border: none;
        font-size: 16px;
        margin: 10px 0;
        width: 100%;
        cursor: pointer;
  
        &:hover {
          background-color: #0056b3;
        }
      }
    }
  }