.btn-change-my-day {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px;
    max-height: 100vh;
    overflow: hidden;

    button {
        align-self: flex-start;
        margin-bottom: 20px;
        background-color: #007bff;
        color: white;
        padding: 5px 15px;
        border: none;
        border-radius: 5px;
        cursor: pointer;
        transition: background-color 0.3s;

        &:hover {
            background-color: #0056b3;
        }
    }

    .titleBtnChangeMyDay {
        h2 {
            font-size: 22px;
            color: #007bff;
            margin-bottom: 15px;
        }
    }

    .listBtnChangeMyDay, .list2BtnChangeMyDay {
        width: 100%;
        margin-bottom: 20px;

        h3 {
            font-size: 18px;
            color: #555;
            margin-bottom: 5px;
        }

        div {
            background-color: #f9f9f9;
            padding: 10px;
            margin-bottom: 10px;
            border-radius: 5px;
            cursor: pointer;
            transition: background-color 0.3s;

            &:hover {
                background-color: #f4f4f8;
            }

            ul {
                list-style: none;
                padding: 0;
                margin-top: 5px;

                li {
                    font-size: 16px;
                    color: #666;
                }
            }
        }
    }
}
