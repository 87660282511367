.affaireDocuments {
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: #fff;

  .icon-affaireDocuments {
    font-size: 20px;
    height: 70px;
    width: 70px;
    color: #007bff;
  }

  .top-affaireDocuments {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 10px;
    background-color: #f0f0f0;
    border-bottom: 1px solid #e0e0e0;
  }

  .btn-retour {
    margin-left: 70px;
    padding: 5px 10px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 14px;

    &:hover {
      background-color: #0056b3;
    }
  }

  .header-affaireDocuments {
    padding: 10px;
    background-color: #f9f9f9;
    border-bottom: 1px solid #ddd;

    .path-affaireDocuments,
    .actions-affaireDocuments {
      display: flex;
      align-items: center;
      gap: 5px;
      font-size: 14px;

      .item {
        display: flex;
        align-items: center;
        gap: 5px;
        cursor: pointer;

        &:hover {
          text-decoration: underline;
        }

        .icon-affaireDocuments {
          width: 20px;
          color: #007bff;
        }
      }
    }
  }

  .content-affaireDocuments {
    padding: 10px;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
    gap: 10px;

    .item {
      border: 1px solid #e0e0e0;
      border-radius: 5px;
      padding: 10px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 5px;
      background-color: #fff;
      box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
      cursor: pointer;

      &:hover {
        box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
      }

      .name {
        font-size: 14px;
        text-align: center;
        min-height: 4.2em;
        /* 14px * 3 lignes = 42px + un peu d'espace pour la respiration */
        display: flex;
        align-items: center;
        justify-content: center;
      }

      img {
        max-width: 100%;
        max-height: 60px;
        object-fit: cover;
      }

      .expired,
      .expiringSoon {
        color: #d9534f;
      }

      .expiringSoon {
        color: #f0ad4e;
      }
    }
  }
}