.singleaffaire {
  .menu {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    margin-left: 0;
    margin-bottom: 60px;

    span {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      background-color: rgb(223, 230, 233);
      color: rgb(18, 97, 227);
      padding: 15px;
      border-bottom: 1px solid rgb(18, 97, 227);
      margin-top: 10px;
      .icon {
        margin-left: 5px;
      }
    }
  }
}
