.ListTache {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 20px;
    box-sizing: border-box;

    .title-ListTache {
        margin-bottom: 20px;

        h3 {
            font-size: 22px;
            color: #007bff;
        }
    }

    .ul-list-tache {
        width: 100%;
        list-style: none;
        padding: 0;
        margin: 0;

        .li-list-tache {
            background-color: #f9f9f9;
            border-radius: 5px;
            padding: 15px;
            margin-bottom: 10px;
            cursor: pointer;
            transition: background-color 0.3s;

            &:hover {
                background-color: #f4f4f8;
            }
        }
    }

    .btn-list-tache {
        background-color: #007bff;
        color: white;
        padding: 10px 20px;
        border: none;
        border-radius: 5px;
        cursor: pointer;
        font-size: 16px;
        transition: background-color 0.3s;

        &:hover {
            background-color: #0056b3;
        }
    }
}