.calendrier-assignation{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    overflow-x: hidden;
    .calendrier-calendrier-assignation{
        width: 100%;
        margin-top: 20px;
        padding: 20px;
    
        
        // Styles spécifiques au composant Calendar
        .react-calendar {
          width: 100%;
          max-width: 400px; // Ajustez en fonction de la largeur souhaitée
          border: none;
          font-family: 'Arial', sans-serif;
    
          // Styliser les boutons de navigation (flèches)
          .react-calendar__navigation button {
            font-size: 2em; // Augmente la taille de la police, ce qui agrandit les flèches
            color: #007bff; // Couleur des flèches, peut être ajustée
          }
    
          .react-calendar__tile--active {
            background: #007bff;
            color: white;
            border: 1px solid white;
            border-radius: 50%;
          }
    
          .marked-date {
            background: #007bff;
            color: white;
          
          }
          
          .attention-needed {
            position: relative;
          }
          
          .attention-needed::after {
            content: '';
            position: absolute;
            top: 5px;
            right: 5px;
            width: 8px;
            height: 8px;
            background-color: red;
            border-radius: 50%;
          }
        }
        .render-tache-calendrier-assignation {
            margin-top: 20px;
      
            .date-du-jour-render-tache {
              display: flex;
              align-items: center;
              justify-content: center;
              padding: 10px;
              color: #007bff;
            }
      
            .render-tache-solo {
              background-color: #f9f9f9;
              padding: 15px;
              margin-bottom: 10px;
              border-radius: 5px;
              box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
      
              .title-tache-solo {
                margin: 0 0 10px 0;
                color: #333;
                font-weight: 500;
              }
      
      
      
              p {
                margin: 0;
                color: #666;
              }
            }
            h4{
              color: #007bff;
              margin: 15px;
            }
            .item-click-calendrier-assignation {
              padding: 10px;
              margin-bottom: 5px;
              background-color: #f9f9f9;
              color: #333;
              box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
            }
          }
        }
      }
      