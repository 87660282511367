.HeuresTachePost {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
  
    input[type="time"] {
      font-size: 16px;
      padding: 10px;
      margin-bottom: 20px;
      border: 1px solid #ccc;
      border-radius: 5px;
      width: 100%;
      max-width: 200px; // Limite la largeur sur les écrans plus larges
    }
  
    button {
      background-color: #007bff;
      color: #fff;
      padding: 10px 15px;
      border: none;
      border-radius: 5px;
      cursor: pointer;
      font-size: 16px;
      transition: background-color 0.3s;
  
      &:hover {
        background-color: #0056b3;
      }
    }
  
    .list-HeuresTachePost {
      width: 100%;
  
      p {
        font-size: 16px;
        margin-bottom: 15px;
      }
  
      ul {
        list-style-type: none;
        padding: 0;
        margin-bottom: 20px;
  
        li {
          background-color: #f8f9fa;
          border: 1px solid #e9ecef;
          border-radius: 5px;
          margin-bottom: 10px;
          padding: 10px;
          font-size: 14px;
        }
      }
    }
  }
  