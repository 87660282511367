.container {
  padding: 10px 20px;

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .hamburger-menu {
      position: inherit;
    }
  }

  .content {
    .title {
      font-size: 24px;
      font-weight: bold;
      margin-bottom: 10px;
    }

    table {
      width: 100%;

      tr:nth-child(even) {
        background-color: #f2f2f2;
      }

      th {
        font-size: 18px;
        font-weight: bold;
      }

      td {
        text-align: center;

        &.action {
          color: #1976d2;
          cursor: pointer;

          &:hover {
            color: #074788;
          }
        }
      }
    }
  }
}