.DefineMyTache {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px;
    max-height: 100vh;
    overflow: hidden;

    .titleDefineMyTache {
        text-align: center;
        h1 {
            font-size: 24px;
            color: #007bff;
            margin-bottom: 10px;
        }
        p {
            font-size: 16px;
            color: #666;
            margin-bottom: 20px;
        }
    }

    .rechDefineMyTache {
        width: 100%;
        padding: 10px;
        margin-bottom: 20px;
        border: 1px solid #ccc;
        border-radius: 5px;
    }

    .listDefineMyTache {
        width: 100%;
        overflow-y: auto;
        .mapDefineMyTache {
            background-color: #f9f9f9;
            padding: 10px;
            margin-bottom: 10px;
            border-radius: 5px;
            cursor: pointer;
            &:hover {
                background-color: #f4f4f8;
            }
        }
    }

    .btnDefineMyTache {
        background-color: #007bff;
        color: white;
        padding: 10px 20px;
        border: none;
        border-radius: 5px;
        cursor: pointer;
        transition: background-color 0.3s;
        &:hover {
            background-color: #0056b3;
        }
    }
}
