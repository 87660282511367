.affaire-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 20px;

  .page-header {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 20px 0;

    h1 {
      color: #007bff;
      margin-bottom: 15px;
    }

    input {
      width: 100%;
      padding: 10px;
      border-radius: 5px;
      border: 1px solid #ccc;
      margin-bottom: 20px;
    }
  }

  p {
    margin: 0;
    padding: 0;
  }

  .affaire-list {
    width: 100%;

    .affaire-item {
      background-color: #f9f9f9;
      border-radius: 5px;
      padding: 10px;
      margin-bottom: 10px;
      transition: background-color 0.3s;
      cursor: pointer;

      &:hover {
        background-color: #f4f4f8;
      }

      p {
        color: #333;
        font-weight: 500;
      }

      .submenu {
        background-color: white;
        box-shadow: 0 2px 4px rgba(0,0,0,0.1);
        border-radius: 5px;
        padding: 10px;
        margin-top: 10px;
        display: flex;
        flex-direction: column;
        .menu-link{
          margin: 5px;
          padding: 5px;
          display: flex;
          align-items: center;
          background-color: #f9f9f9;
          text-decoration: none;
          .icon-affaire-page{
            color: #007bff;
          }
        p {
          display: flex;
          align-items: center;
          padding: 8px;
          margin-left: 10px;
          border-radius: 5px;
          transition: background-color 0.2s ease-in-out;
          color: #007bff;
          font-weight: 500;


          &:hover {
            background-color: #f4f4f8;
            text-decoration: none;
          }

          svg {
            margin-right: 8px;
          }
        }
      }
      }
    }
  }
}