.lack-materiel {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;

  h2 {
    color: #007bff;
    margin-bottom: 20px;
  }

  form {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .MuiTextField-root {
    width: 100%;
    margin-bottom: 15px;
  }

  .btn-lack-materiel {
    background-color: #007bff;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    text-transform: uppercase;
    font-weight: bold;
    transition: background-color 0.3s;

    &:hover {
      background-color: #0056b3;
    }
  }

  .btn-retour-lack-materiel {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    margin-bottom: 20px;

    button {
      color: #f0f0f0;
      background-color: #007bff;
      border: 1px solid #ccc;
      padding: 5px 10px;
      border-radius: 5px;
      cursor: pointer;
      transition: background-color 0.3s;

      &:hover {
        background-color: #e1e1e1;
      }
    }
  }
}
