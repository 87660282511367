.assignMyTache {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px;
    max-height: 100vh;
    overflow: hidden;

    .headerassignMyTache {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;

        button {
            padding: 5px 10px;
            background-color: #007bff;
            color: white;
            border: none;
            border-radius: 5px;
            cursor: pointer;
            &:hover {
                background-color: #0056b3;
            }
        }

        h1 {
            font-size: 22px;
            color: #007bff;
        }
    }

    .titletachassignMyTache {
        margin-top: 20px;
        h2 {
            font-size: 20px;
            color: #666;
        }
    }

    .selectedMembers, .teamMembersassignMyTache {
        width: 100%;
        overflow-y: auto;
        margin-top: 10px;

        .listassignMyTache, .list2assignMyTache {
            background-color: #f9f9f9;
            padding: 10px;
            margin-bottom: 10px;
            border-radius: 5px;
            cursor: pointer;
            display: flex;
            justify-content: space-between;
            align-items: center;

            &:hover {
                background-color: #f4f4f8;
            }

            .btnassignMyTache {
                border: none;
                background-color: #ff4d4d;
                color: white;
                padding: 5px 10px;
                border-radius: 5px;
                cursor: pointer;

                &:hover {
                    background-color: #ff6666;
                }
            }
        }
    }

    .btn2assignMyTache {
        background-color: #007bff;
        color: white;
        padding: 10px 20px;
        border: none;
        border-radius: 5px;
        cursor: pointer;
        transition: background-color 0.3s;
        &:hover {
            background-color: #0056b3;
        }
        margin-top: 20px;
    }
}
