.rapport-taches-chantier {
    display: flex;
    flex-direction: column;
   
    padding: 20px;
    background-color: #f5f5f5;
    border-radius: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    margin: 20px;
    width: 100%;

    .title-rapport-taches-chantier {
        display: flex;
        align-items: center;
        margin-bottom: 20px;

        h2 {
            color: #007bff;
        }
    }

    .tache-chantier-item-container-rtc {
        background-color: #fff;
        padding: 15px;
        margin-bottom: 10px;
        border-radius: 5px;
        box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
        cursor: pointer;
        transition: background-color 0.3s;

        &:hover {
            background-color: #f4f4f8;
        }
    }

    .MuiDialog-paper {
        border-radius: 15px;
    }

    .MuiDialogContent-root {
        padding: 20px;
    }

    .MuiFormControl-root {
        margin: 10px 0;
    }

    .MuiButton-root {
        background-color: #007bff;
        color: white;
        margin-top: 20px;
        border: none;
        border-radius: 5px;
        padding: 10px 20px;
        cursor: pointer;
        transition: background-color 0.3s;

        &:hover {
            background-color: #0056b3;
        }
    }
}