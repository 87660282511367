.ChangeMyTacheSelect {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px;
    max-height: 100vh;
    overflow: hidden;
  
    .btnretour {
      align-self: flex-start;
      margin-bottom: 20px;
      background-color: #007bff;
      color: white;
      padding: 5px 15px;
      border: none;
      border-radius: 5px;
      cursor: pointer;
      transition: background-color 0.3s;
  
      &:hover {
        background-color: #0056b3;
      }
    }
  
    .titleChangeMyTacheSelect {
      h1 {
        font-size: 22px;
        color: #007bff;
        margin-bottom: 15px;
      }
    }
  
    .containerChangeMyTacheSelect {
      width: 100%;
  
      span {
        font-size: 18px;
        color: #555;
        margin-bottom: 10px;
        display: block;
      }
  
      .desChangeMyTacheSelect, .listChangeMyTacheSelect {
        background-color: #f9f9f9;
        padding: 10px;
        margin-bottom: 10px;
        border-radius: 5px;
        cursor: pointer;
  
        h3 {
          font-size: 16px;
          color: #666;
          margin: 0;
        }
  
        &:hover {
          background-color: #f4f4f8;
        }
      }
  
      .list2ChangeMyTacheSelect {
        margin-top: 20px;
      }
    }
  }