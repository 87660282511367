.contain-ReunionRapportVisite {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #f5f5f5;
    border-radius: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    width: 100%;
    overflow-y: auto;
    button {
        background-color: #007bff;
        color: white;
        border: none;
        border-radius: 5px;
        padding: 5px 10px;
        cursor: pointer;
        transition: background-color 0.3s;

        &:hover {
            background-color: #0056b3;
        }
    }

    .btn-group-ReunionRapportVisite {
        display: flex;
        justify-content: space-between;
        width: 100%;
        margin-bottom: 20px;

        .btn-ajout-personne-reu-rapport-visit,
        .btn-ajout-point-reu-rapport-visit {
            display: flex;
            align-items: center;

            label {
                font-weight: bold;
                margin-left: 10px;
            }

            button {
                background-color: #007bff;
                color: white;
                border: none;
                border-radius: 5px;
                padding: 5px 10px;
                cursor: pointer;
                transition: background-color 0.3s;

                &:hover {
                    background-color: #0056b3;
                }
            }
        }
    }

    .render-peronnes-ReunionRapportVisite, .render-points-abor-ReunionRapportVisite {
        width: 100%;
        margin-bottom: 20px;

        h3 {
            color: #007bff;
            margin-bottom: 10px;
        }

        .render-personne-reu-rapport-visit, .render-point-abor-reu-rapport-visit {
            background-color: #fff;
            padding: 15px;
            margin-bottom: 10px;
            border-radius: 5px;
            box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);

            p {
                margin: 5px 0;
            }

            .btn-ajout-comm-reu-rapport-visit{
                display: flex;
                align-items: center;
                label {
                    font-weight: bold;
                    margin-left: 10px;
                }
    
                button {
                    background-color: #007bff;
                    color: white;
                    border: none;
                    border-radius: 5px;
                    padding: 5px 10px;
                    cursor: pointer;
                    transition: background-color 0.3s;
    
                    &:hover {
                        background-color: #0056b3;
                    }
                }
            }
        }
    }

}
