.ProgressionTachesDay {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 15px;
  overflow-y: auto;
  padding-bottom: 200px;

  .progression-taches-day-item {
    border-bottom: 2px solid #e0e0e0; 
    padding-bottom: 10px; 
    margin-bottom: 10px; 

    .title-ptd-h3 {
      h3 {
        font-size: 18px;
        margin-bottom: 10px;
      }
    }

    .btngroup-ptd {
      margin: 5px 0;
      width: 100%;

      .MuiButton-root {
        padding: 10px 0;
        font-size: 16px;
      }
    }

    
    .switch-ptd {
      display: flex;

      .MuiFormControlLabel-root {
        margin-top: 0px;
      }
      span {
        margin-top: 0px;
      }
    }
  }
}
