.amelioration-rapport-visite {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    background-color: #f5f5f5;
    border-radius: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    margin: 20px;
    width: 100%;

    .title-amelioration-rapport-visite {
        margin-bottom: 20px;

        h2 {
            color: #007bff;
        }
    }

    .MuiTextField-root {
        margin-bottom: 15px;
        width: 100%;

        label {
            color: #007bff;
        }

        .MuiInput-underline:before {
            border-bottom: 1px solid #007bff;
        }
    }

    .contain-ajout-arv {
        margin-bottom: 20px;
        width: 100%;

        .title-arv {
            margin-bottom: 10px;

            h3 {
                color: #007bff;
            }
        }

        .btn-ajout-arv {
            display: flex;
            align-items: center;
            margin-top: 10px;

            button {
                background-color: #007bff;
                color: white;
                margin-right: 10px;
                padding: 5px 10px;
                border: none;
                border-radius: 5px;
                cursor: pointer;
                transition: background-color 0.3s;

                &:hover {
                    background-color: #0056b3;
                }
            }
        }
    }

    button {
        background-color: #007bff;
        color: white;
        padding: 10px;
        border: none;
        border-radius: 5px;
        cursor: pointer;
        transition: background-color 0.3s;

        &:hover {
            background-color: #0056b3;
        }
    }
}