.container-question-reponse {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 15px;
    min-height: 100vh; // Hauteur minimale pour s'assurer que tout le contenu est visible
  padding-bottom: 200px;
    .question {
      background-color: #f9f9f9;
      padding: 15px;
      margin-bottom: 15px;
      border-radius: 5px;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
      width: 100%; // Assurez-vous que la question prend toute la largeur
  
    p {
      font-size: 16px;
      color: #333;
      margin-bottom: 10px;
    }
  
    .MuiFormControlLabel-root {
      margin-right: 20px;
    }
  
    .MuiCheckbox-root {
      color: #007bff;
    }
  
    button {
      background-color: #007bff;
      color: white;
      border: none;
      border-radius: 5px;
      padding: 5px 10px;
      cursor: pointer;
      margin-left: 10px;
  
      &:hover {
        background-color: #0056b3;
      }
    }
    .MuiSelect-select {
      max-width: 250px; // Définissez ici la largeur maximale souhaitée
      word-wrap: break-word;
  
  }


    .commentaire {
      color: #666;
      font-size: 14px;
      margin-top: 10px;
    }
  }
  
  #commentaireGeneralDay {
    width: 100%;
    height: 50px;
    padding: 10px;
    margin-top: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  button {
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    padding: 10px 20px;
    cursor: pointer;
    margin-top: 20px;
    transition: background-color 0.3s;
  
    &:hover {
      background-color: #0056b3;
    }
  }
}