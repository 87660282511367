#root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.blocAffaire {
  display: flex;
  flex-direction: column;
  text-transform: capitalize;
  margin-bottom: 60px;
  flex: 1 0 auto; 
  max-height: calc(100vh - 60px); 


  .retour {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 5px;
    color: rgb(18, 97, 227);
  }

  .top {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10px;

    h4 {
      display: flex;
      align-items: center;
      padding-top: 10px;
    }
  }

  .contain {

    margin-top: 10px;
    overflow-y: scroll; 

    .bloc {
      border: 1px solid rgb(223, 230, 233);

      .title {
        padding: 10px;
        font-weight: bold;
      }

      &.open {
        .title {
          background-color: rgb(18, 97, 227);
          color: rgb(223, 230, 233);
        }
        .card {
          border: 1px solid rgb(18, 97, 227);
        }
      }

      .card {
        border-top: 1px solid rgb(223, 230, 233);
        padding-bottom: 5px;
        margin-bottom: 5px;
        padding-left: 15px;
        .titleligne {
          font-weight: bold;
          padding-top: 10px;
          padding-left: -10px;
          padding-bottom: 10px;
        }
      }
    }
}}



