.container {
    display: flex;
    flex-direction: column;
    padding: 10px;
    background-color: #f2f2f2;
    height: calc(100vh - 18px);

    .box {
        border: 1px solid #f4f4f4;
        background-color: #fff;
        -webkit-box-shadow: 5px 5px 27px 5px rgba(56, 56, 56, 0.2);
        box-shadow: 5px 5px 27px 5px rgba(56, 56, 56, 0.2);
        border-radius: 5px;
        overflow-x: hidden;
        overflow-y: auto;
    }

    .title {
        font-size: 25px;
        font-weight: 500;
        margin-bottom: 22px;
        margin-left: 55px;
        padding-top: 3px;
    }

    .chat {
        display: flex;
        flex-direction: row;
        gap: 10px;
        height: calc(100% - 60px);

        .list {
            display: flex;
            flex-direction: column;
            min-width: 300px;
            width: 20%;

            .no-chats {
                align-self: center;
                font-weight: 600;
                font-size: 15px;
                text-align: center;
                margin: 20px;
            }

            @media only screen and (max-width: 800px) {
                width: 100%;

                &.selected {
                    display: none;
                }
            }

            .chat {
                padding: 0px 10px;
                height: 50px;
                position: relative;
                display: flex;
                flex-direction: column;
                justify-content: center;
                cursor: pointer;
                border-bottom: 1px solid #f2f2f2;
                border-right: 4px solid transparent;

                &:hover {
                    background-color: #f2f2f2;
                }

                &.active {
                    background-color: #f2f2f2;
                    border-right: 4px solid var(--bg2);
                }

                .name {
                    font-size: 15px;
                    font-weight: 600;
                    white-space: pre;
                    width: 80%;
                    overflow: hidden;
                    display: flex;
                    align-items: center;

                    .isAffaire {
                        width: 15px;
                        height: 15px;
                        margin: 0px 2px;
                    }
                }

                .lastMessage {
                    display: flex;
                    font-size: 10px;
                    margin-top: -8px;
                    color: #383838;
                    white-space: pre;
                    width: 80%;
                    overflow: hidden;

                    .lastMessageSentBy {
                        font-weight: 600;
                    }
                }

                .date {
                    font-size: 10px;
                    position: absolute;
                    right: 5px;
                    top: 6px;
                }
            }
        }

        .content {
            display: flex;
            flex: 1;
            gap: 10px;
            height: 100%;
            flex-direction: column;

            @media only screen and (max-width: 800px) {
                display: none;
            }

            &.selected {
                display: flex;
            }

            .header {
                display: flex;
                flex-direction: row;
                align-items: center;
                height: 50px;
                padding: 0px 10px;
                gap: 10px;
                justify-content: flex-start;

                .icon {
                    height: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    cursor: pointer;
                    font-size: 20px;
                    color: #383838;

                    &:hover {
                        color: #171717;
                    }
                }

                .title {
                    font-size: 20px;
                    margin: auto 0px;
                    font-weight: 500;
                }

                .refresh {
                    cursor: pointer;
                    position: absolute;
                    right: 18px;
                    font-size: 30px;
                    color: #383838;
                    transition: 1.5s;

                    &:hover {
                        transform: rotate(360deg);
                        color: #171717;
                    }
                }
            }

            .messages {
                display: flex;
                flex-direction: column-reverse;
                padding: 15px 15px;
                height: 100%;
                gap: 15px;
                position: relative;

                .loading {
                    align-self: center;
                    font-weight: 600;
                    width: 80px !important;
                    height: 80px !important;
                    position: absolute;
                    top: 35%;
                }

                .no-messages {
                    align-self: center;
                    font-weight: 600;
                    font-size: 22px;
                    position: absolute;
                    text-align: center;
                    top: 35%;
                    margin: 0px 20px;
                }

                .message {
                    display: flex;
                    flex-direction: column;
                    width: fit-content;
                    max-width: 65%;
                    // min-width: 200px;

                    &.me {
                        align-self: flex-end;

                        .description {
                            text-align: right;
                        }

                        .file {
                            align-self: flex-end;
                        }
                    }

                    .file {
                        display: block;
                        text-decoration: underline;
                        color: rgb(86, 86, 240);
                        margin-left: 1px;
                    }

                    .image {
                        >img {
                            width: 100%;
                            height: auto;
                        }
                    }

                    .content {
                        display: flex;
                        height: fit-content;
                        background-color: #eeeeee;
                        -webkit-box-shadow: 4px 5px 10px 1px rgba(0, 0, 0, 0.2);
                        box-shadow: 4px 5px 10px 1px rgba(0, 0, 0, 0.2);
                        padding: 10px 15px;
                        border-radius: 10px;
                    }

                    .description {
                        margin: 5px 10px 0px 10px;
                        font-size: 9px;
                        font-weight: 200;
                    }

                }
            }

            .input {
                display: flex;
                align-items: center;
                justify-content: space-between;
                flex-direction: row;
                height: 90px;

                textarea {
                    flex: 1;
                    border: none;
                    resize: none;
                    font-size: 18px;
                    height: calc(90px - 30px);
                    padding: 0px 10px;

                    &:focus {
                        outline: none !important;
                    }
                }

                .selectedFiles {
                    padding: 10px;
                    position: relative;

                    img {
                        height: 50px;
                        width: auto;
                        filter: blur(1px);
                    }

                    .close {
                        position: absolute;
                        cursor: pointer;
                        color: red;
                        transition: 0.5s;

                        right: -15px;
                        top: 7px;

                        &:hover {
                            transform: rotate(-90deg);
                        }
                    }
                }

                .actions {
                    height: 100%;
                    display: flex;
                    flex-direction: column;

                    .icon {
                        &:first-child {
                            border-bottom: 1px #383838 solid;
                        }

                        flex: 1;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        cursor: pointer;
                        padding: 0px 15px;
                        background-color: #f2f2f2;
                        color: #383838;

                        &:hover {
                            background-color: #383838;
                            color: #f2f2f2;
                        }

                        svg {
                            font-size: 23px;
                        }

                        .item {
                            display: flex;
                            flex-direction: row;
                            gap: 4px;
                        }
                    }
                }
            }
        }
    }
}

@media only screen and (min-width: 800px) {
    .arrowIcon {
        display: none !important;
    }
}