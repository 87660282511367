.CreationRapportDeVisite {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding-top: 20px;
  overflow-x: hidden;
  overflow-y: auto;
  flex-wrap: nowrap;

  
    .btn-group-crea-rapport-visite {
      display: flex;
      width: 100%;
      justify-content: space-between;
      padding: 0 20px;
      margin-bottom: 20px;
  
      button {
        background-color: #007bff;
        color: white;
        padding: 10px 15px;
        border: none;
        border-radius: 5px;
        cursor: pointer;
        transition: background-color 0.3s;
  
        &:hover {
          background-color: #0056b3;
        }
      }
    }
  
    .render-crea-rapport-visite {
      width: 100%;
      overflow-x: hidden;
      overflow-y: auto;
      display: flex;
      justify-content: center;

    }
  }

  