.SelectOrCreateAffaire {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 20px;

    .title-SelectOrCreateAffaire {
        text-align: center;

        h2 {
            font-size: 24px;
            margin-bottom: 15px;
            color: #007bff;
        }
    }

    .btn-SelectOrCreateAffaire {
        display: flex;
        flex-direction: column;
        gap: 20px;
        width: 100%;

        button {
            padding: 10px 20px;
            border: none;
            border-radius: 5px;
            background-color: #007bff;
            color: white;
            font-weight: 500;
            cursor: pointer;
            transition: background-color 0.3s;

            &:hover {
                background-color: #0056b3;
            }
        }
    }
}