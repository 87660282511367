.HeuresTachePut {
    display: flex;
    flex-direction: column;
    align-items: center;

  .title-HeuresTachePut{
    padding-top: 20px;
    padding-bottom: 10px;
  }

  .contain-HeuresTachePut{
    display: flex;
    width: 80%;
    align-items: center;
    justify-content: space-between;
font-size: 20px;
padding-top: 10px;
padding-bottom: 10px;
    input[type="time"] {
      font-size: 16px;
      padding: 10px;
      margin-bottom: 20px;
      border: 1px solid #ccc;
      border-radius: 5px;
      width: 100%;
      max-width: 200px; // Limite la largeur sur les écrans plus larges
    }
  
    button {
      background-color: #007bff;
      color: #fff;
      padding: 5px 10px;
      border: none;
      border-radius: 5px;
      cursor: pointer;
      font-size: 16px;
      transition: background-color 0.3s;
  
      &:hover {
        background-color: #0056b3;
      }
    }
}
}