.SelectAffaire {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 20px;
    box-sizing: border-box;

    .title-select-affaire {
        margin-bottom: 20px;

        h3 {
            font-size: 22px;
            color: #007bff;
        }
    }

    .select-affaire-contain {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 15px;

        .select-select-affaire-contain {
            width: 100%;
            padding: 10px;
            border-radius: 5px;
            border: 1px solid #ccc;
            background-color: #fff;
            font-size: 16px;
            color: #333;
        }

        .btn-select-affaire-contain {
            background-color: #007bff;
            color: white;
            padding: 10px 20px;
            border: none;
            border-radius: 5px;
            cursor: pointer;
            font-size: 16px;
            transition: background-color 0.3s;

            &:hover {
                background-color: #0056b3;
            }
        }
    }
}
