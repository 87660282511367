.ChangeMyTeam {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px;
    max-height: 100vh;
    overflow: hidden;

    .titleChangeMyTeam {
        h1 {
            font-size: 22px;
            color: #007bff;
            margin-bottom: 10px;
        }
    }

    .descriptionChangeMyTeam, .nouveauChangeMyTeam {
        width: 100%;
        margin-bottom: 20px;
        overflow-y: auto;
height: 300px;

        h2 {
            font-size: 18px;
            color: #555;
            margin-bottom: 10px;
            position: sticky;
        }

        .listactuelChangeMyTeam, .listnewChangeMyTeam {
            display: flex;
            justify-content: space-between;
            align-items: center;
            background-color: #f9f9f9;
            padding: 10px;
            margin-bottom: 10px;
            border-radius: 5px;
            cursor: pointer;
            transition: background-color 0.3s;

            &:hover {
                background-color: #f4f4f8;
            }

            button {
                border: none;
                background-color: #007bff;
                color: white;
                padding: 5px 10px;
                border-radius: 5px;
                cursor: pointer;

                &:hover {
                    background-color: #0056b3;
                }
            }
        }

        .btnnewChangeMyTeam {
            background-color: #28a745;
            &:hover {
                background-color: #218838;
            }
        }
    }

    .btnvalidChangeMyTeam {
        background-color: #007bff;
        color: white;
        padding: 10px 20px;
        border: none;
        border-radius: 5px;
        cursor: pointer;
        transition: background-color 0.3s;

        &:hover {
            background-color: #0056b3;
        }
    }

}
