
.btn-end-my-day{
display: flex;
align-items: center;
justify-content: center;
padding-top: 20px;
    button{
    background-color: #007bff;
    color: white;
    padding: 15px 20px;
    border-radius: 25px;
    border: none;
    font-size: 16px;
    margin: 10px 0;
    width: 100%;
    cursor: pointer;

    &:hover {
      background-color: #0056b3;
    }
}
}