.change-my-tache {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px;
    max-height: 100vh;
    overflow: hidden;

    button {
        align-self: flex-start;
        margin-bottom: 20px;
        background-color: #007bff;
        color: white;
        padding: 5px 15px;
        border: none;
        border-radius: 5px;
        cursor: pointer;
        transition: background-color 0.3s;

        &:hover {
            background-color: #0056b3;
        }
    }

    .titleChangeMyTache {
        h1 {
            font-size: 22px;
            color: #007bff;
            margin-bottom: 15px;
        }
    }

    .containerChangeMyTache {
        width: 100%;

        .list1ChangeMyTache, .list2ChangeMyTache {
            margin-bottom: 20px;
            background-color: #f9f9f9;
            padding: 10px;
            border-radius: 5px;

            h2, p {
                font-size: 18px;
                color: #555;
                margin-bottom: 5px;
            }

            ul {
                list-style: none;
                padding: 0;
                margin-top: 5px;

                li {
                    font-size: 16px;
                    color: #666;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    margin-bottom: 10px;

                    button {
                        border: none;
                        background-color: #ff4d4d;
                        color: white;
                        padding: 5px 10px;
                        border-radius: 5px;
                        cursor: pointer;

                        &:hover {
                            background-color: #ff6666;
                        }
                    }
                }
            }
        }

        .btn1ChangeMyTache, .btn2ChangeMyTache {
            background-color: #007bff;
            color: white;
            padding: 10px 20px;
            border: none;
            border-radius: 5px;
            cursor: pointer;
            transition: background-color 0.3s;

            &:hover {
                background-color: #0056b3;
            }
        }
    }
}
