.loading-screen {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh; // Prend toute la hauteur de l'écran
    background-color: white;
  }
  
  .logo-animation {
    width: 100px; // Définissez la taille souhaitée pour le logo
    animation: wave-motion 3s ease-in-out infinite;
  }
  
  @keyframes wave-motion {
    0%, 100% {
      transform: translateX(0) translateY(0);
    }
    25% {
      transform: translateX(-25px) translateY(-5px); // Se déplace légèrement vers la gauche et vers le haut
    }
    50% {
      transform: translateX(-50px) translateY(0); // Se déplace plus à gauche et retourne au niveau initial
    }
    75% {
      transform: translateX(-25px) translateY(5px); // Se déplace légèrement vers la gauche et vers le bas
    }
  }
  